import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function AddProjectButtons({ buttonProps }: any) {
  const navigate = useNavigate();

  const handleNavigateToCreateProject = () => {
    navigate("/projects/create");
  };

  return (
    <Button
      {...buttonProps}
      onClick={handleNavigateToCreateProject}
      variant="primary"
      fontSize={14}
    >
      + Create Project
    </Button>
  );
}

export default AddProjectButtons;
