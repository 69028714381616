import {
  Text,
  VStack,
  Heading,
  ButtonGroup,
  Button,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import AddProjectButtons from "../AddProjectButtons";

interface WelcomeProps {
  isAuthenticated?: boolean;
}

function Welcome({ isAuthenticated = false }: WelcomeProps) {
  return (
    <Flex direction="column" align="center" mt={10}>
      <VStack spacing={8} maxWidth={"60%"} marginTop={"8%"}>
        <Heading as="h1" size="xl" textAlign="center">
          {process.env.REACT_APP_NODE_ENV === "development"
            ? "Welcome to the EarthFast Dashboard [Alpha]"
            : "Welcome to the EarthFast Dashboard"}
        </Heading>
        <Text fontSize="lg" textAlign="center" fontStyle={"italic"}>
          EarthFast provides fully decentralized frontend hosting with community
          governance and a global network of node operators. Visit{" "}
          <Link
            style={{ color: "blue" }}
            target="_blank"
            to="https://earthfast.com"
          >
            EarthFast.com
          </Link>{" "}
          for more information.
        </Text>
        <Text fontSize="lg" textAlign="center">
          Create and manage website projects on EarthFast effortlessly. There
          are no prerequisites to creating projects via this dashboard. No
          blockchain experience and no tokens necessary. Just dive in and start
          hosting your website or frontend on EarthFast today!
        </Text>
        {isAuthenticated ? (
          <>
            <Text fontSize="lg" textAlign="center">
              Get started by adding a new project!
            </Text>
            <AddProjectButtons buttonProps={{ size: "lg" }} />
          </>
        ) : (
          <>
            <Text fontSize="lg" textAlign="center">
              Sign Up for a free account or Log In to get started!
            </Text>
            <ButtonGroup>
              <Button size="lg" as={Link} to="/login" variant="primaryInverted">
                Log In
              </Button>
              <Button size="lg" as={Link} to="/signup" variant="primary">
                Sign Up
              </Button>
            </ButtonGroup>
          </>
        )}
      </VStack>
    </Flex>
  );
}

export default Welcome;
