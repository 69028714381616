import { useState, useEffect, ReactNode } from "react";
import { Box, CloseButton, useDisclosure } from "@chakra-ui/react";

const TopAlertBanner = ({
  id,
  content,
  expirationDays,
  bgColor = "primary",
}: {
  id: string;
  content: ReactNode;
  expirationDays: number;
  bgColor?: string;
}) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    const storedMessages = JSON.parse(
      localStorage.getItem("topMessages") || "{}",
    );
    const messageData = storedMessages[id];

    if (messageData) {
      const { closed, timestamp } = messageData;
      const daysSinceClosed = (Date.now() - timestamp) / (1000 * 60 * 60 * 24);

      if (closed && daysSinceClosed < expirationDays) {
        setShouldShow(false);
      } else {
        delete storedMessages[id];
        localStorage.setItem("topMessages", JSON.stringify(storedMessages));
      }
    }
  }, [id, expirationDays]);

  const handleClose = () => {
    onClose();
    const storedMessages = JSON.parse(
      localStorage.getItem("topMessages") || "{}",
    );
    storedMessages[id] = {
      closed: true,
      timestamp: Date.now(),
    };
    localStorage.setItem("topMessages", JSON.stringify(storedMessages));
    setShouldShow(false);
  };

  if (!shouldShow) return null;

  return (
    <Box
      bg={bgColor}
      color="white"
      py={2}
      px={4}
      display={isOpen ? "flex" : "none"}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flex="1">{content}</Box>
      <CloseButton size="sm" onClick={handleClose} />
    </Box>
  );
};

export default TopAlertBanner;
