import { Text, Flex, Button, Box, Heading } from "@chakra-ui/react";
import { ProjectUpdatesProps } from "./ProjectUpdates.types";
import LoadingProjects from "../../components/LoadingProjects";
import ProjectUpdateRow from "../../components/ProjectUpdateRow";

function ProjectUpdates({
  projectType,
  projectName,
  projectUpdates,
  selectedPipelineId,
  loadingLogs,
  logs,
  isLoading,
  isMakingCurrentBuild,
  isTriggeringNewBuild,
  goBack,
  handleMakeCurrentBuild,
  handleTriggerNewBuild,
}: ProjectUpdatesProps) {
  if (isLoading) return <LoadingProjects />;

  return (
    <Flex direction="column" align="center" mt={10} width="100%">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w={{ base: "90%", md: "80%" }}
        mb={8}
      >
        <Flex flexDirection="column">
          <Heading as="h2" fontSize="3xl" fontWeight="bold">
            Builds
          </Heading>
          <Text>{projectName}</Text>
        </Flex>
        <Flex alignItems="center" gap={3}>
          {projectType !== "upload" && (
            <Button
              onClick={handleTriggerNewBuild}
              variant="primary"
              fontWeight={500}
              isLoading={isTriggeringNewBuild}
            >
              Trigger New Build
            </Button>
          )}
          <Button onClick={goBack} variant="outline" fontWeight={500}>
            Back
          </Button>
        </Flex>
      </Flex>
      <Box w={{ base: "90%", md: "80%" }}>
        {!projectUpdates?.length ? (
          <Text>
            No Builds found. Builds are auto generated on new deployments.
          </Text>
        ) : (
          projectUpdates?.map((update) => (
            <ProjectUpdateRow
              key={update.id}
              projectUpdate={update}
              selectedPipelineId={selectedPipelineId}
              loadingLogs={loadingLogs}
              logs={logs}
              isMakingCurrentBuild={
                isMakingCurrentBuild || isTriggeringNewBuild
              }
              handleMakeCurrentBuild={handleMakeCurrentBuild}
            />
          ))
        )}
      </Box>
    </Flex>
  );
}

export default ProjectUpdates;
