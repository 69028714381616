import { Text, Box, Link as ChakraLink, Tooltip } from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns";
import { FiHelpCircle } from "react-icons/fi";
import { BASE_HASH_URL } from "../../constants";

interface ProjectTransactionsProps {
  createdTransaction?: Transaction;
  lastBuildTransaction?: Transaction;
  fontSize?: string;
}

const ProjectTransactions = ({
  createdTransaction,
  lastBuildTransaction,
  fontSize = "smaller",
}: ProjectTransactionsProps) => {
  return (
    <Box mt={1}>
      {createdTransaction && (
        <Text color="gray.500" fontSize={fontSize}>
          <Text as="span" fontWeight="semibold">
            Created:
          </Text>{" "}
          {format(new Date(createdTransaction.timestamp), "PPP")} -{" "}
          <ChakraLink
            href={`${BASE_HASH_URL}/${createdTransaction.transactionHash}`}
            target="_blank"
          >
            <Text as="span" textDecoration="underline">
              Transaction
            </Text>
          </ChakraLink>{" "}
          <Tooltip
            label="Contracts have not been verified yet"
            fontSize="xs"
            placement="right"
          >
            <Box
              as="span"
              display="inline-block"
              verticalAlign="middle"
              bottom={0.3}
              position="relative"
            >
              <FiHelpCircle size={12} />
            </Box>
          </Tooltip>
        </Text>
      )}
      {lastBuildTransaction && (
        <Text color="gray.500" fontSize={fontSize}>
          <Text as="span" fontWeight="semibold">
            Last published:
          </Text>{" "}
          {formatDistanceToNow(new Date(lastBuildTransaction.timestamp), {
            addSuffix: true,
          })}{" "}
          <ChakraLink
            href={`${BASE_HASH_URL}/${lastBuildTransaction.transactionHash}`}
            target="_blank"
          >
            <Text as="span" textDecoration="underline">
              Transaction
            </Text>
          </ChakraLink>
          <Tooltip
            label="Contracts have not been verified yet"
            fontSize="xs"
            placement="right"
          >
            <Box
              as="span"
              display="inline-block"
              verticalAlign="middle"
              bottom={0.3}
              position="relative"
              ml={1}
            >
              <FiHelpCircle size={12} />
            </Box>
          </Tooltip>
        </Text>
      )}
    </Box>
  );
};

export default ProjectTransactions;
