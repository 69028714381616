import {
  Text,
  Flex,
  Button,
  VStack,
  HStack,
  Collapse,
  Box,
  Spinner,
  useDisclosure,
  Heading,
  Link,
  Badge,
} from "@chakra-ui/react";
import { FaArrowUp, FaCodeBranch, FaCodeCommit } from "react-icons/fa6";
import { setStatusColor } from "../../util";
import { BASE_HASH_URL } from "../../constants";
import { formatDistanceToNow } from "date-fns";
import { BsDownload, BsListCheck } from "react-icons/bs";
import { Action, ProjectUpdateRowProps, Step } from "./ProjectUpdateRow.types";

function ProjectUpdateRow({
  projectUpdate,
  selectedPipelineId,
  loadingLogs,
  logs,
  isMakingCurrentBuild,
  handleMakeCurrentBuild,
}: ProjectUpdateRowProps) {
  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      py={4}
      px={6}
      mb={4}
      boxShadow="sm"
      background="white"
      key={projectUpdate.id}
      justifyContent="space-between"
      width="100%"
    >
      <Flex flexDirection="column" gap={1}>
        <Flex alignItems="center" gap={3}>
          <Heading as="h3" fontWeight="bold" fontSize={18}>
            Build No. {projectUpdate.id}
          </Heading>
          <Badge
            bg={`${setStatusColor(projectUpdate.status)}.50`}
            color={`${setStatusColor(projectUpdate.status)}.600`}
            textTransform="capitalize"
            px={2}
            rounded="400px"
            fontWeight={500}
            borderColor={`${setStatusColor(projectUpdate.status)}.300`}
            borderWidth="1px"
          >
            <Flex alignItems="center" gap={1}>
              <Flex
                width="6px"
                height="6px"
                borderRadius="50%"
                bg={`${setStatusColor(projectUpdate.status)}.600`}
              />
              {projectUpdate.status || "Pending"}
            </Flex>
          </Badge>
          {!!projectUpdate.isCurrentBuild &&
            projectUpdate?.status === "success" && (
              <Badge
                bg="blue.50"
                color="blue.600"
                textTransform="capitalize"
                px={2}
                rounded="400px"
                fontWeight={500}
                borderColor="blue.300"
                borderWidth="1px"
              >
                <Flex alignItems="center" gap={1}>
                  <FaArrowUp color="primary" />
                  Current Build
                </Flex>
              </Badge>
            )}
        </Flex>
        {projectUpdate.additionalProps?.lastCommitMessage && (
          <Flex alignItems="center" gap={1}>
            <FaCodeBranch />
            <Text fontSize="sm">
              {projectUpdate.additionalProps?.branchName || "main"}
            </Text>
            {!!projectUpdate.additionalProps?.lastCommitMessage && (
              <>
                <Text fontSize="sm"> / </Text>
                <FaCodeCommit />
                <Text fontSize="sm">
                  {projectUpdate.additionalProps?.lastCommitMessage}
                </Text>
              </>
            )}
          </Flex>
        )}
        {!!projectUpdate.transaction && (
          <Flex flexDirection="column">
            <Text color="gray.500" fontSize="smaller">
              <Text as="span" fontWeight="semibold">
                Published:
              </Text>{" "}
              {formatDistanceToNow(
                new Date(projectUpdate.transaction.timestamp),
                {
                  addSuffix: true,
                },
              )}
            </Text>
            <Text color="gray.500" fontSize="smaller">
              <Link
                href={`${BASE_HASH_URL}/${projectUpdate.transaction.transactionHash}`}
                target="_blank"
              >
                <Text as="span" textDecoration="underline">
                  Transaction link
                </Text>
              </Link>
            </Text>
          </Flex>
        )}
      </Flex>
      <HStack>
        {projectUpdate.circleciJobId && (
          <Button
            as={Link}
            colorScheme="gray"
            variant="outline"
            size="sm"
            href={`/logs/${projectUpdate.circleciJobId}`}
            isLoading={loadingLogs[projectUpdate.circleciJobId]}
            spinner={<Spinner />}
            // eslint-disable-next-line react/jsx-no-undef
            leftIcon={<BsListCheck />}
          >
            {selectedPipelineId === projectUpdate.circleciJobId
              ? "Hide Logs"
              : "View Logs"}
          </Button>
        )}
        {projectUpdate.bundleUrl && (
          <Button
            as="a"
            href={projectUpdate.bundleUrl}
            download
            colorScheme="gray"
            variant="outline"
            size="sm"
            leftIcon={<BsDownload />}
          >
            Download Assets
          </Button>
        )}
        {!projectUpdate.isCurrentBuild && (
          <Button
            size="sm"
            variant="primaryInverted"
            onClick={() => handleMakeCurrentBuild(projectUpdate)}
            isLoading={isMakingCurrentBuild}
          >
            Make Current Build
          </Button>
        )}
      </HStack>
      {selectedPipelineId === projectUpdate.circleciJobId &&
        logs[projectUpdate.circleciJobId] &&
        logs[projectUpdate.circleciJobId].length > 0 && (
          <VStack spacing={4} mt={4} w="100%">
            <Text fontWeight="bold">Logs:</Text>
            {logs[projectUpdate.circleciJobId].map((log, index) => (
              <Flex
                key={index}
                // bg="gray.100"
                borderRadius="lg"
                shadow="base"
                p={4}
                w="100%"
                flexDirection="column"
              >
                <Text>Job: {log.job.name}</Text>
                <Text>Status: {log.job.status}</Text>
                <Text>Started at: {log.job.started_at}</Text>
                <Text>Stopped at: {log.job.stopped_at}</Text>
                <Text>Steps:</Text>
                {log.steps.map((step: Step, stepIndex: number) => (
                  <VStack
                    key={stepIndex}
                    alignItems="flex-start"
                    // bg="white"
                    borderRadius="md"
                    shadow="sm"
                    p={2}
                    mt={2}
                  >
                    <Text fontWeight="bold">Step: {step.name}</Text>
                    {step.actions.map((action: Action, actionIndex: number) => (
                      <Box key={actionIndex} ml={4} mt={1} w="100%">
                        <Text>Action: {action.name}</Text>
                        <Text>Status: {action.status}</Text>
                        <Text>Start time: {action.start_time}</Text>
                        <Text>End time: {action.end_time}</Text>
                        <DropdownOutput output={action.output} />
                      </Box>
                    ))}
                  </VStack>
                ))}
              </Flex>
            ))}
          </VStack>
        )}
    </Flex>
  );
}

function DropdownOutput({ output }: { output: string }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box mt={2}>
      <Button size="sm" onClick={onToggle}>
        {isOpen ? "Hide Output" : "View Output"}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box
          p={4}
          mt={2}
          // bg="gray.50"
          borderRadius="md"
          shadow="sm"
          maxH="300px"
          overflowY="auto"
        >
          <pre>{output}</pre>
        </Box>
      </Collapse>
    </Box>
  );
}

export default ProjectUpdateRow;
