import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Text, List, ListItem, useToast } from "@chakra-ui/react";

interface FolderUploadProps {
  onFilesSelected: (files: any[]) => void;
  isDisabled?: boolean;
}

const FolderUpload: React.FC<FolderUploadProps> = ({
  onFilesSelected,
  isDisabled,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const toast = useToast();

  const onDrop = (acceptedFiles: any) => {
    const folderFiles = acceptedFiles.map((file: any) => {
      const path = file.webkitRelativePath || file.path;
      return {
        path: path.startsWith("/") ? path.substring(1) : path,
        file: file,
      };
    });

    const indexHtmlExists = folderFiles.some((file: any) => {
      const parts = file.path.split("/");
      return parts.length === 2 && parts[1] === "index.html";
    });

    if (indexHtmlExists) {
      setFiles(folderFiles);
      onFilesSelected(folderFiles);
    } else {
      setFiles([]);
      onFilesSelected([]);
      toast({
        title: "Folder Validation Failed",
        description:
          "index.html not found at the top level of the uploaded folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    noKeyboard: true,
    useFsAccessApi: false,
    disabled: isDisabled,
  });

  return (
    <Box padding="20px" margin="0 auto">
      <Box
        {...getRootProps()}
        width="100%"
        height="30vh"
        p={5}
        borderWidth="2px"
        borderRadius="md"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        cursor="pointer"
        marginBottom="20px"
      >
        <input
          /* prettier-ignore */
          // @ts-ignore
          {...getInputProps({ webkitdirectory: "true", directory: "true" })}
        />
        <Text>
          Click here or drag-and-drop a folder to upload your build assets.
          <br />
          The folder must contain index.html at the root level.
        </Text>
      </Box>
      {files.length > 0 && (
        <List padding="0" maxHeight={"30vh"} width={"100%"} overflow="scroll">
          {files.map(({ path }) => (
            <ListItem key={path} marginBottom="5px">
              <Box
                borderWidth="1px"
                borderRadius="md"
                padding="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize="14px">{path}</Text>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default FolderUpload;
