import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  Box,
  Input,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Flex,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { createProject, updateProject } from "../../api";
import LoadingStepper from "../LoadingStepper";
import FormHelpPopover from "../FormHelpPopover";
import { encryptWithPublicKey } from "../../util";
import { LOADING_STEPS_CREATE_PROJECT } from "../../constants";
import { isProjectInfoUrl } from "../../util";

const initialState = {
  name: "",
  email: "",
  buildCommands: {
    buildCommand: "",
    buildDir: "",
    environmentVariables: "",
    outputDir: "",
    packageInstallCommand: "",
  },
  hostedProjectUrl: "",
  projectType: "",
  projectInfo: {
    url: "",
    branchName: "",
  } as ProjectInfoUrl,
} as ProjectObjectToCreate;

const ProjectConfigUrl = ({
  isEditing = false,
  projectData: projectDataInitial,
}: {
  isEditing?: boolean;
  projectData?: ProjectObject;
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [projectCreating, setProjectCreating] = useState(false);
  const [projectData, setProjectData] = useState(
    projectDataInitial || initialState,
  );
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  // Refs for scrolling to error fields
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const buildDirRef = useRef<HTMLInputElement>(null);
  const outputDirRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, email, buildCommands } = projectData;
    const projectInfo = projectData.projectInfo as ProjectInfoUrl;

    const errors: Record<string, string> = {};
    if (!name) errors.name = "Project name is required";
    if (!email) errors.email = "Contact email is required";
    if (!projectInfo.url) errors.url = "Project URL is required";

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // Scroll to the first field with an error
      const firstErrorField = Object.keys(errors)[0];
      const refs = {
        name: nameRef,
        email: emailRef,
        url: urlRef,
        buildDir: buildDirRef,
        outputDir: outputDirRef,
      };

      const refToScroll = refs[firstErrorField as keyof typeof refs];
      if (refToScroll && refToScroll.current) {
        refToScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      return;
    }

    try {
      let response = null;
      const encryptedEnvironmentVariables = encryptWithPublicKey(
        buildCommands.environmentVariables,
      );
      const payload = {
        name,
        email,
        buildCommands: {
          ...buildCommands,
          environmentVariables: encryptedEnvironmentVariables,
        },
        projectType: "url",
        projectInfo: {
          url: projectInfo.url,
          branchName: projectInfo.branchName,
          domain: "github",
        },
      };
      setProjectCreating(true);
      if (isEditing) {
        response = await updateProject(
          (projectData as ProjectObject).id,
          payload,
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to update project.");
        }

        toast({
          title: "Project Updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        return;
      }

      response = await createProject(payload);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create project.");
      }

      toast({
        title: "Project Created",
        description: "Project successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProjectCreating(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    if (projectData.buildCommands && name in projectData.buildCommands) {
      setProjectData((prev) => ({
        ...prev,
        buildCommands: { ...prev.buildCommands, [name]: value },
      }));
    } else if (projectData.projectInfo && name in projectData.projectInfo) {
      setProjectData((prev) => ({
        ...prev,
        projectInfo: { ...prev.projectInfo, [name]: value },
      }));
    } else {
      setProjectData((prev) => ({ ...prev, [name]: value }));
    }

    // Clear the error for the field being edited
    setFormErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit} noValidate>
        <Flex marginTop="40px" border="2x solid #eeeeee" width="100%">
          <Flex
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
            width="100%"
          >
            <VStack
              spacing={6}
              backgroundColor="gray.50"
              padding={5}
              width="100%"
            >
              {!isEditing && (
                <>
                  <FormControl
                    isInvalid={!!formErrors.name}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.name ? "red.500" : "inherit"}
                    >
                      Project Name
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      ref={nameRef}
                      backgroundColor="white"
                      name="name"
                      value={projectData.name}
                      onChange={handleInputChange}
                      placeholder="Enter project name"
                    />
                    <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.email}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.email ? "red.500" : "inherit"}
                    >
                      Contact Email
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <FormHelpPopover text="This email serves as a contact for the project in case node operators want to communicate with you or users want to get in touch to verify authenticity."></FormHelpPopover>
                    <Input
                      ref={emailRef}
                      backgroundColor="white"
                      name="email"
                      value={projectData.email}
                      onChange={handleInputChange}
                      placeholder="Enter contact email"
                    />
                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.url}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.url ? "red.500" : "inherit"}
                    >
                      Project Url
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <FormHelpPopover text="You can find the URL by clicking on the 'Code' button on the GitHub repository and copying the HTTPS URL."></FormHelpPopover>
                    <Input
                      ref={urlRef}
                      backgroundColor="white"
                      name="url"
                      value={(projectData.projectInfo as ProjectInfoUrl).url}
                      onChange={handleInputChange}
                      placeholder="e.g. https://github.com/earthfast/demo-project"
                    />
                    <FormErrorMessage>{formErrors.url}</FormErrorMessage>
                  </FormControl>
                </>
              )}
              <FormControl>
                <FormLabel display="inline-block">Branch Name</FormLabel>
                <FormHelpPopover text="Specify the branch to use for this project."></FormHelpPopover>
                {isProjectInfoUrl(projectData.projectInfo) && (
                  <Input
                    backgroundColor="white"
                    name="branchName"
                    value={
                      (projectData.projectInfo as ProjectInfoUrl).branchName
                    }
                    onChange={handleInputChange}
                    placeholder="If not specified, uses default branch"
                  />
                )}
              </FormControl>
              <FormControl>
                <FormLabel display="inline-block">
                  Package Install Command
                </FormLabel>
                <FormHelpPopover text="Command to install project dependencies."></FormHelpPopover>
                <Input
                  backgroundColor="white"
                  name="packageInstallCommand"
                  value={projectData.buildCommands.packageInstallCommand}
                  onChange={handleInputChange}
                  placeholder="e.g., npm install or yarn install"
                />
              </FormControl>
              <FormControl>
                <FormLabel display="inline-block">Build Command</FormLabel>
                <FormHelpPopover text="This command will initiate building static assets for your project. Basic Dependencies for Ubuntu, Node.js, NPM and more are supported by the build environment."></FormHelpPopover>
                <Input
                  backgroundColor="white"
                  name="buildCommand"
                  value={projectData.buildCommands.buildCommand}
                  onChange={handleInputChange}
                  placeholder="e.g., npm run build"
                />
              </FormControl>
              <FormControl>
                <FormLabel display="inline-block">
                  Build Command Directory
                </FormLabel>
                <FormHelpPopover text="Specify the directory in which to run the build command."></FormHelpPopover>
                <Input
                  ref={buildDirRef}
                  backgroundColor="white"
                  name="buildDir"
                  value={projectData.buildCommands.buildDir}
                  onChange={handleInputChange}
                  placeholder="Directory in which to run above Build Command e.g. `.` or `./src`"
                />
              </FormControl>
              <FormControl>
                <FormLabel display="inline-block">
                  Build Assets Output Directory
                </FormLabel>
                <FormHelpPopover text="The directory where static assets are exported to. This directory should contain an index.html at the root level."></FormHelpPopover>
                <Input
                  ref={outputDirRef}
                  backgroundColor="white"
                  name="outputDir"
                  value={projectData.buildCommands.outputDir}
                  onChange={handleInputChange}
                  placeholder="Output directory from above Build Command, e.g. `./build`"
                />
              </FormControl>
            </VStack>
          </Flex>
        </Flex>
        <Accordion allowMultiple marginTop="40px" border="2x solid #eeeeee">
          <AccordionItem
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
          >
            <AccordionButton backgroundColor={"gray.100"}>
              <Box flex="1" textAlign="left">
                <Heading fontSize="xl" as="h3" fontWeight="semibold">
                  Advanced
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack spacing={6}>
                <FormControl>
                  <FormLabel display="inline-block">
                    Environment Variables
                  </FormLabel>
                  <FormHelpPopover text="These will be injected into the build system when building the static assets. You can paste a .env file directly."></FormHelpPopover>
                  <Textarea
                    backgroundColor="white"
                    name="environmentVariables"
                    value={projectData.buildCommands.environmentVariables}
                    onChange={handleInputChange}
                    placeholder="Enter environment variables"
                    rows={4}
                  />
                </FormControl>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Button
          type="submit"
          variant="primary"
          mt={4}
          width="100%"
          isLoading={projectCreating}
        >
          {isEditing ? "Update Project" : "Create Project"}
        </Button>
      </form>
      {projectCreating && !isEditing && (
        <LoadingStepper steps={LOADING_STEPS_CREATE_PROJECT} />
      )}
    </Box>
  );
};

export default ProjectConfigUrl;
