import {
  Box,
  VStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Center,
  Text,
  Link,
  Heading,
  Divider,
} from "@chakra-ui/react";
import FolderUpload from "../../components/FolderUpload";
import FormHelpPopover from "../../components/FormHelpPopover";
import { capitalize } from "../../util";

interface FileDeploymentProps {
  uploadMethod: string;
  bundleUrl: string;
  checksum: string;
  projectName?: string;
  isDeploying: boolean;
  onSetFolderFiles: (files: any[]) => void;
  onSetBundleUrl: (url: string) => void;
  onSetUploadMethod: (uploadMethod: string) => void;
  onSetChecksum: (checksum: string) => void;
  handleFileSubmission: () => void;
  goBack: () => void;
}

function FileDeployment({
  uploadMethod,
  bundleUrl,
  checksum,
  projectName = "",
  isDeploying,
  onSetFolderFiles,
  onSetBundleUrl,
  onSetChecksum,
  onSetUploadMethod,
  handleFileSubmission,
  goBack,
}: FileDeploymentProps) {
  return (
    <Box width="full" maxWidth="80%" margin="0 auto" padding={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={8}>
        <Heading fontSize={30} fontWeight="semibold">
          Deployment
          <Text
            as="span"
            fontSize={18}
            display="block"
            color="textColor"
            fontWeight={400}
            minHeight={21.6}
          >
            {capitalize(projectName)}
          </Text>
        </Heading>
        <Button onClick={goBack} variant="outline" fontWeight={500}>
          Back
        </Button>
      </Flex>
      <Box bg="white" p={10} borderWidth={1} borderRadius="lg">
        <Flex justify="space-around" mb={4}>
          <Center
            borderWidth={1}
            borderColor="rgba(0, 0, 0, 0.24)"
            p={3}
            borderRadius={8}
          >
            <Button
              colorScheme="teal"
              borderRadius={0}
              variant="link"
              color={uploadMethod === "file" ? "primary" : "#667085"}
              opacity={uploadMethod === "file" ? 1 : 0.7}
              fontWeight={uploadMethod === "file" ? "semibold" : "normal"}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => onSetUploadMethod("file")}
            >
              Upload Assets Folder
            </Button>
            <Divider
              orientation="vertical"
              height="25px"
              marginX="12px"
              bg="rgba(0, 0, 0, 0.24)"
            />
            <Button
              colorScheme="teal"
              borderRadius={0}
              variant="link"
              color={uploadMethod === "url" ? "primary" : "#667085"}
              opacity={uploadMethod === "url" ? 1 : 0.7}
              fontWeight={uploadMethod === "url" ? "semibold" : "normal"}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => onSetUploadMethod("url")}
            >
              Upload Earthfast Bundle URL
            </Button>
          </Center>
        </Flex>
        <VStack spacing={4} align="center">
          {uploadMethod === "file" ? (
            <Box>
              <FolderUpload
                onFilesSelected={onSetFolderFiles}
                isDisabled={isDeploying}
              />
            </Box>
          ) : uploadMethod === "url" ? (
            <>
              <Flex justify="center" mb={4}>
                <Text>
                  {"Use this page only if you built your site with the "}
                  <Link
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/earthfast/armada-cli?tab=readme-ov-file#armada-cli"
                  >
                    Earthfast CLI
                  </Link>
                  {"."}
                </Text>
              </Flex>
              <FormControl>
                <FormLabel display={"inline-block"}>Bundle URL</FormLabel>
                <FormHelpPopover text="Must be a publicly accessible URL containing your bundle, as generated by the Earthfast CLI (`armada-cli bundle create`)"></FormHelpPopover>
                <Input
                  value={bundleUrl}
                  onChange={(e) => onSetBundleUrl(e.target.value)}
                  placeholder="Enter bundle URL"
                  disabled={isDeploying}
                />
              </FormControl>
              <FormControl>
                <FormLabel display={"inline-block"}>Bundle Checksum</FormLabel>
                <FormHelpPopover text="The checksum generated by the Earthfast CLI (`armada-cli bundle checksum`)"></FormHelpPopover>
                <Input
                  value={checksum}
                  onChange={(e) => onSetChecksum(e.target.value)}
                  placeholder="Enter checksum"
                  disabled={isDeploying}
                />
              </FormControl>
            </>
          ) : null}
          <Button
            colorScheme="blue"
            onClick={handleFileSubmission}
            width="40%"
            maxWidth={531}
            minWidth={200}
            isLoading={isDeploying}
          >
            Create Build
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default FileDeployment;
