import { useState } from "react";
import {
  Button,
  Box,
  Flex,
  Center,
  Text,
  Link,
  Heading,
  Divider,
} from "@chakra-ui/react";
import ProjectConfigGithub from "../../components/ProjectConfigGithub";
import ProjectConfigUrl from "../../components/ProjectConfigUrl";
import ProjectConfigManual from "../../components/ProjectConfigManual";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

function CreateProject() {
  const [projectType, setProjectType] = useState("github");
  const navigate = useNavigate();

  const renderProjectComponent = () => {
    switch (projectType) {
      case "github":
        return <ProjectConfigGithub />;
      case "url":
        return <ProjectConfigUrl />;
      case "manual":
        return <ProjectConfigManual />;
      default:
        return;
    }
  };

  const renderProjectDescription = () => {
    switch (projectType) {
      case "github":
        return (
          <>
            <Text fontWeight="bold" color="green.500" mb={2}>
              This is the recommended option.
            </Text>
            {
              "Add a public GitHub repository that you own directly by authenticating with GitHub. Automatically updates your Earthfast site with each commit pushed to a specified branch and includes CI checks on Github."
            }
          </>
        );
      case "url":
        return "Add a public GitHub repository by URL. Use this if you don't want to authenticate your GitHub, or don't own the repo. Automatically updates your Earthfast site with each commit pushed to a specified branch.";
      case "manual":
        return (
          <>
            <Text fontWeight="bold" color="orange.500" mb={2}>
              Warning: Advanced Users Only!
            </Text>
            {
              "Manually update projects. There are two ways to manually upload content:"
            }
            <br />
            {
              "1. Upload a folder of built static assets from your frontend repo eg HTML, CSS, JS, images, etc."
            }
            <br />
            {"2. Use existing EarthFast assets built via the CLI ("}
            <Link
              color="blue"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.armadanetwork.com/armada-network-docs/host-a-project-on-armada#h.gsecmjpmqpmt"
            >
              docs
            </Link>
            {")"}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <Box
      px={5}
      py={12}
      borderWidth="1px"
      borderRadius="lg"
      width="80%"
      marginX="auto"
      bg="white"
      position="relative"
    >
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        onClick={() => navigate("/projects")}
        position="absolute"
        top={4}
        left={4}
      >
        Back
      </Button>
      <Heading
        fontSize={32}
        as="h3"
        fontWeight="semibold"
        textAlign="center"
        paddingBottom="24px"
        mt={8}
      >
        Project Setup
      </Heading>
      <Flex justify="space-around" mb={4}>
        <Center
          borderWidth={1}
          borderColor="rgba(0, 0, 0, 0.24)"
          p={3}
          borderRadius={8}
        >
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant="link"
            color={projectType === "github" ? "primary" : "#667085"}
            opacity={projectType === "github" ? 1 : 0.7}
            fontWeight={projectType === "github" ? "semibold" : "normal"}
            onClick={() => setProjectType("github")}
            sx={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Import by GitHub Auth
          </Button>
          <Divider
            orientation="vertical"
            height="25px"
            marginX="12px"
            bg="rgba(0, 0, 0, 0.24)"
          />
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant="link"
            color={projectType === "url" ? "primary" : "#667085"}
            opacity={projectType === "url" ? 1 : 0.7}
            fontWeight={projectType === "url" ? "semibold" : "normal"}
            onClick={() => setProjectType("url")}
            sx={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Import by GitHub URL
          </Button>
          <Divider
            orientation="vertical"
            height="25px"
            marginX="12px"
            bg="rgba(0, 0, 0, 0.24)"
          />
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant="link"
            color={projectType === "manual" ? "primary" : "#667085"}
            opacity={projectType === "manual" ? 1 : 0.7}
            fontWeight={projectType === "manual" ? "semibold" : "normal"}
            onClick={() => setProjectType("manual")}
            sx={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Manual Project Upload
          </Button>
        </Center>
      </Flex>
      <Center>
        <Box width={{ base: "60%" }} border="1px 1px solid black;">
          <Flex justify="center" mb={4}>
            <Text textAlign="center" color="#667085" opacity={0.7}>
              {renderProjectDescription()}
            </Text>
          </Flex>
        </Box>
      </Center>
      <Center>
        <Box width={{ base: "90%", md: "70%" }} minWidth="300px">
          {renderProjectComponent()}
        </Box>
      </Center>
    </Box>
  );
}

export default CreateProject;
