import { useEffect, useMemo, useState } from "react";
import { deleteProject, getProjects } from "../../api";
import Projects from "./Projects";
import { useUser } from "../../context/UserContext";
import { useToast } from "@chakra-ui/react";

function ProjectsContainer() {
  const toast = useToast();
  const { isAuthenticated, isLoadingUser } = useUser();
  const [projects, setProjects] = useState<ProjectObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState<number | null>(
    null,
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteConfirmation = (id: number) => {
    setProjectIdToDelete(id);
  };

  const handleConfirmDelete = async () => {
    if (projectIdToDelete !== null) {
      setIsDeleting(true);
      await handleDeleteProject(projectIdToDelete);
      setIsDeleting(false);
      setProjectIdToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setProjectIdToDelete(null);
  };

  const handleDeleteProject = async (id: number): Promise<void> => {
    try {
      const response = await deleteProject(id);

      if (response.ok) {
        setProjects((prevProjects) =>
          prevProjects.filter((proj) => proj.id !== id),
        );
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Server error");
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Failed to delete project";
      toast({
        title: message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const data = await getProjects();
      setProjects(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          "Authentication check or project fetch failed:",
          error.message,
        );
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    fetchProjects();
  }, [isAuthenticated]);

  useEffect(() => {
    const polling = async () => {
      try {
        if (!projects.some(({ isBuilding }) => isBuilding)) return null;
        const updatedProjects = await getProjects();
        const hasBuildingProject = updatedProjects.some(
          ({ isBuilding }) => isBuilding,
        );

        if (!hasBuildingProject) {
          clearInterval(intervalId);
          setProjects(updatedProjects);
        }
      } catch (error) {
        clearInterval(intervalId);
      }
    };
    const intervalId = setInterval(polling, 20000);

    return () => clearInterval(intervalId);
  }, [isAuthenticated, projects]);

  return (
    <Projects
      projects={useMemo(() => projects.reverse(), [projects])}
      isLoading={isLoading || !!isLoadingUser}
      isAuthenticated={!!isAuthenticated}
      handleDeleteConfirmation={handleDeleteConfirmation}
      projectIdToDelete={projectIdToDelete}
      handleConfirmDelete={handleConfirmDelete}
      handleCancelDelete={handleCancelDelete}
      isDeleting={isDeleting}
    />
  );
}

export default ProjectsContainer;
