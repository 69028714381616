import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Logs from "./Logs";
import { useToast } from "@chakra-ui/react";
import { getLogs } from "../../api";
import { Log } from "./Logs.types";

function LogsContainer() {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  async function fetchLogs() {
    if (!pipelineId) return;
    setLoading(true);
    try {
      const data = await getLogs(pipelineId);
      setLogs(data);
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast({
        title: "Error fetching logs",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  function goBack() {
    navigate(-1);
  }

  return <Logs goBack={goBack} logs={logs} loading={loading} />;
}

export default LogsContainer;
