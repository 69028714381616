import { Link } from "react-router-dom";
import {
  Box,
  Center,
  Heading,
  Button,
  Flex,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FiUpload, FiEye, FiList, FiTrash } from "react-icons/fi";
import { ArrowBackIcon } from "@chakra-ui/icons";
import GitHubProjects from "../../components/ProjectConfigGithub";
import AddProjectByUrl from "../../components/ProjectConfigUrl";
import ManualProjectUpload from "../../components/ProjectConfigManual";
import ProjectInfoDisplay from "../../components/ProjectInfoDisplay";
import ProjectTransactions from "../../components/ProjectTransactions";
import { setStatusColor } from "../../util";

interface ProjectViewProps {
  createdTransaction?: Transaction;
  lastBuildTransaction?: Transaction;
  isBuilding: boolean;
  projectData: ProjectObject | null;
  isDeleting: boolean;
  isDeleteAlertOpen: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  handleDeleteClick: () => void;
  handleDeleteConfirm: () => void;
  onCloseConfirm: () => void;
  onGoBack: () => void;
}

export default function ProjectView({
  createdTransaction,
  lastBuildTransaction,
  isBuilding,
  projectData,
  isDeleting,
  isDeleteAlertOpen,
  cancelRef,
  handleDeleteClick,
  handleDeleteConfirm,
  onCloseConfirm,
  onGoBack,
}: ProjectViewProps) {
  return (
    <Box
      px={5}
      py={12}
      borderWidth="1px"
      borderRadius="lg"
      width="80%"
      marginX="auto"
      bg="white"
      position="relative"
    >
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        onClick={onGoBack}
        position="absolute"
        top={4}
        left={4}
      >
        Back
      </Button>
      <Box width={{ base: "90%", md: "70%" }} minWidth="300px" marginX="auto">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex direction="column" mb={6}>
            <Heading
              fontSize={32}
              as="h3"
              fontWeight="semibold"
              textAlign="left"
              mt={8}
              mb={1}
            >
              {projectData?.name}
            </Heading>
            {projectData && (
              <div>
                <ProjectInfoDisplay project={projectData} fontSize="md" />
                {!isBuilding && (
                  <ProjectTransactions
                    createdTransaction={createdTransaction}
                    lastBuildTransaction={lastBuildTransaction}
                    fontSize="md"
                  />
                )}
                {projectData?.buildStatus && (
                  <Badge
                    bg={`${setStatusColor(projectData.buildStatus)}.50`}
                    color={`${setStatusColor(projectData.buildStatus)}.600`}
                    textTransform="capitalize"
                    px={2}
                    rounded="400px"
                    fontWeight={500}
                    borderColor={`${setStatusColor(projectData.buildStatus)}.300`}
                    borderWidth="1px"
                  >
                    <Flex alignItems="center" gap={1}>
                      <Flex
                        width="6px"
                        height="6px"
                        borderRadius="50%"
                        bg={`${setStatusColor(projectData.buildStatus)}.600`}
                      />
                      {projectData.buildStatus === "pending"
                        ? "Building"
                        : projectData.buildStatus}
                    </Flex>
                  </Badge>
                )}
              </div>
            )}
          </Flex>
          {projectData && (
            <Flex justifyContent="center" mt={4} mb={4}>
              {projectData.projectType === "upload" && (
                <Button
                  as={Link}
                  to={`/projects/${projectData.id}/deploy`}
                  leftIcon={<FiUpload />}
                  colorScheme="gray"
                  variant="outline"
                  size="sm"
                  mr={2}
                >
                  Upload
                </Button>
              )}
              <Button
                leftIcon={<FiList />}
                colorScheme="gray"
                as={Link}
                to={`/project_updates/${projectData.id}`}
                variant="outline"
                size="sm"
                mr={2}
              >
                Builds
              </Button>
              {projectData.hasSuccessfulBuild &&
                projectData.hostedProjectUrl && (
                  <Button
                    leftIcon={<FiEye />}
                    colorScheme="gray"
                    as="a"
                    target="_blank"
                    href={`https://${projectData.hostedProjectUrl}`}
                    variant="outline"
                    size="sm"
                    mr={2}
                  >
                    View site
                  </Button>
                )}
              <Button
                leftIcon={<FiTrash />}
                colorScheme="red"
                variant="outline"
                size="sm"
                onClick={handleDeleteClick}
                isLoading={isDeleting}
              >
                Delete
              </Button>

              <AlertDialog
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseConfirm}
                isCentered
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Project
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure you want to delete this project? This action
                      cannot be undone.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onCloseConfirm}>
                        Cancel
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={handleDeleteConfirm}
                        ml={3}
                      >
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Flex>
          )}
        </Flex>
      </Box>
      {projectData && (
        <Center pt={3}>
          <Box width={{ base: "90%", md: "70%" }} minWidth="300px">
            <Flex alignItems="center" justifyContent="space-between">
              <Heading as="h4" size="md" mb={-3}>
                Edit Project
              </Heading>
            </Flex>
            {projectData.projectType === "github" && (
              <GitHubProjects isEditing={true} projectData={projectData} />
            )}
            {projectData.projectType === "url" && (
              <AddProjectByUrl isEditing={true} projectData={projectData} />
            )}
            {projectData.projectType === "upload" && (
              <ManualProjectUpload isEditing={true} projectData={projectData} />
            )}
          </Box>
        </Center>
      )}
    </Box>
  );
}
