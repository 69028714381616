import { useEffect } from "react";
import { useSteps, useMediaQuery } from "@chakra-ui/react";

import LoadingStepper from "./LoadingStepper";
import { LoadingStepperProps } from "./LoadingStepper.types";

function LoadingStepperContainer({
  steps,
}: Pick<LoadingStepperProps, "steps">) {
  const { activeStep: step, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [isSmallScreen] = useMediaQuery("(max-width: 1100px)");

  useEffect(() => {
    if (step < steps.length - 1) {
      const timeout = setTimeout(() => {
        setActiveStep((prev) => prev + 1);
      }, steps[step].duration);
      return () => clearTimeout(timeout);
    }
  }, [step, steps, setActiveStep]);

  return (
    <LoadingStepper
      steps={steps}
      isSmallScreen={isSmallScreen}
      currentIndexStep={step}
    />
  );
}

export default LoadingStepperContainer;
