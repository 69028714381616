export const LOADING_STEPS_CREATE_PROJECT = [
  { title: "Funding Wallet", duration: 10000 },
  { title: "Creating Project", duration: 20000 },
  { title: "Depositing Escrow", duration: 20000 },
  { title: "Selecting Nodes", duration: 0 },
];

export const BASE_HASH_URL =
  process.env.REACT_APP_NODE_ENV === "mainnet"
    ? "https://etherscan.io/tx/"
    : "https://sepolia.etherscan.io/tx/";
