import {
  Box,
  Spinner,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from "@chakra-ui/react";
import { LoadingStepperProps } from "./LoadingStepper.types";

function LoadingStepper({
  steps,
  isSmallScreen = false,
  currentIndexStep = 0,
}: LoadingStepperProps) {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      bg="rgba(255, 255, 255, 0.7)"
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="70%" bg="white" p="6" borderRadius="md" boxShadow="md">
        <Stepper
          index={currentIndexStep}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator
                sx={{
                  "[data-status=complete] &": {
                    background: "#2E93FF",
                  },
                  "[data-status=active] &": {
                    background: "white",
                    borderColor: "#2E93FF",
                  },
                  "[data-status=incomplete] &": {
                    background: "white",
                    borderColor: "#2E93FF",
                    textColor: "#002C53",
                    fontWeight: "semibold",
                  },
                }}
              >
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<Spinner color="#002C53" />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle>
                  <Text color="#002C53">{step.title}</Text>
                </StepTitle>
              </Box>
              {index < steps.length - 1 && <StepSeparator />}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}

export default LoadingStepper;
