import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
  Link as ChakraLink,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { addToWaitlist } from "../../api";

interface SignUpInputs {
  name: string;
  email: string;
  company: string;
  usage: string;
}

const WaitlistRegistration = () => {
  const [inputs, setInputs] = useState<SignUpInputs>({
    name: "",
    email: "",
    company: "",
    usage: "",
  });
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const toast = useToast();
  const navigate = useNavigate();

  if (user) {
    return <Navigate to="/" />;
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToWaitlist(inputs);

      toast({
        title: "Waitlist Signup Successful",
        description:
          "You've successfully joined the Earthfast waitlist. We'll review your application and get back to you soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/");
    } catch (error) {
      console.error("Signup error:", error);
      toast({
        title: "Signup Error",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width="100%"
      maxW="500px"
      mx="auto"
      my="auto"
      p="10"
      borderWidth="1px"
      borderRadius="lg"
      bg="white"
    >
      <Heading fontSize="xl" textAlign="center" mb="3" as="h2">
        Join the Earthfast Waitlist
      </Heading>
      <Text
        mb="4"
        textAlign="center"
        fontSize="sm"
        color="gray.600"
        lineHeight="1.5"
      >
        Be among the first to experience Earthfast's revolutionary decentralized
        hosting platform. Secure your spot on our exclusive waitlist and receive
        a personal invitation when it's your turn to join.
      </Text>
      <form onSubmit={handleSignUp}>
        <VStack spacing="5">
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={inputs.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={inputs.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Company</FormLabel>
            <Input
              type="text"
              name="company"
              value={inputs.company}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>How do you want to use Earthfast?</FormLabel>
            <Textarea
              name="usage"
              value={inputs.usage}
              onChange={handleInputChange}
              placeholder="Tell us about your intended use of Earthfast"
            />
          </FormControl>
          <Button
            width="full"
            type="submit"
            isLoading={loading}
            variant="primary"
          >
            Secure My Spot
          </Button>
        </VStack>
      </form>
      <Text align="center" mt="4" fontSize="sm" color="gray.500">
        By joining, you agree to receive updates about Earthfast.
      </Text>
      <Text align="center" mt="4">
        Already have an account?{" "}
        <ChakraLink as={Link} to="/login" color="primary">
          Log in
        </ChakraLink>
      </Text>
    </Box>
  );
};

export default WaitlistRegistration;
