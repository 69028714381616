import { useMemo } from "react";
import {
  Flex,
  Button,
  IconButton,
  Link as ChakraLink,
  Box,
  Heading,
  Badge,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiTrash, FiUpload, FiEye, FiList } from "react-icons/fi";
import { getRecentPublishTransaction, setStatusColor } from "../../util";
import ProjectInfoDisplay from "../ProjectInfoDisplay";
import ProjectTransactions from "../ProjectTransactions";

interface Props {
  project: ProjectObject;
  onDeleteConfirmation: (id: number) => void;
  isDeleting: boolean;
}

const ProjectRow = ({ project, onDeleteConfirmation, isDeleting }: Props) => {
  const createdTransaction = project.transactions?.find(
    ({ transactionType }) => transactionType === "createProject",
  );
  const lastBuildTransaction = getRecentPublishTransaction(
    project.transactions,
  );
  const buildStatus = useMemo(() => project.buildStatus, [project.buildStatus]);

  const handleDeleteClick = () => {
    onDeleteConfirmation(project.id);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      py={4}
      px={6}
      mb={4}
      boxShadow="sm"
      background="white"
    >
      <Flex
        justify="space-between"
        align={{ base: "flex-start", md: "center" }}
        direction={{ base: "column", md: "row" }}
        gap="20px"
      >
        <Box>
          <ChakraLink fontSize="lg" href={`/projects/${project.id}`}>
            <Flex alignItems="center" gap={2}>
              <Heading fontWeight="bold" fontSize={18}>
                {project.name}
              </Heading>
              {buildStatus && (
                <Badge
                  bg={`${setStatusColor(buildStatus)}.50`}
                  color={`${setStatusColor(buildStatus)}.600`}
                  textTransform="capitalize"
                  px={2}
                  rounded="400px"
                  fontWeight={500}
                  borderColor={`${setStatusColor(buildStatus)}.300`}
                  borderWidth="1px"
                >
                  <Flex alignItems="center" gap={1}>
                    <Flex
                      width="6px"
                      height="6px"
                      borderRadius="50%"
                      bg={`${setStatusColor(buildStatus)}.600`}
                    />
                    {buildStatus === "pending" ? "Building" : buildStatus}
                  </Flex>
                </Badge>
              )}
            </Flex>
          </ChakraLink>
          <Box color="gray.700" fontSize="sm">
            <ProjectInfoDisplay project={project} />
          </Box>
          <ProjectTransactions
            createdTransaction={createdTransaction}
            lastBuildTransaction={lastBuildTransaction}
          />
        </Box>
        <Flex>
          {project.projectType === "upload" && (
            <Button
              as={Link}
              to={`/projects/${project.id}/deploy`}
              leftIcon={<FiUpload />}
              colorScheme="gray"
              variant="outline"
              size="sm"
              mr={2}
            >
              Upload
            </Button>
          )}
          <Button
            leftIcon={<FiList />}
            colorScheme="gray"
            as={Link}
            to={`/project_updates/${project.id}`}
            variant="outline"
            size="sm"
            mr={2}
          >
            Builds
          </Button>
          {project.hasSuccessfulBuild && project.hostedProjectUrl && (
            <Button
              leftIcon={<FiEye />}
              colorScheme="gray"
              as="a"
              target="_blank"
              href={`https://${project.hostedProjectUrl}`}
              variant="outline"
              size="sm"
              mr={2}
            >
              View site
            </Button>
          )}
          <IconButton
            icon={<FiTrash />}
            colorScheme="gray"
            variant="outline"
            size="sm"
            aria-label="Delete"
            onClick={handleDeleteClick}
            isLoading={isDeleting}
            isDisabled={isDeleting}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProjectRow;
