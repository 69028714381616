import { useUser } from "../../context/UserContext";
import { Flex } from "@chakra-ui/react";
import Header from "../Header";
import Router from "../Router";
import StagingWarningBanner from "../StagingWarningBanner";
import Footer from "../Footer";

function App() {
  const { logoutFn } = useUser();

  return (
    <Flex direction="column" minHeight="100vh">
      <StagingWarningBanner />
      <Header logoutFn={logoutFn} />
      <Flex flex="1" width="100%">
        <Router />
      </Flex>
      <Footer />
    </Flex>
  );
}

export default App;
