import {
  Flex,
  Button,
  ButtonGroup,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { FaGithub } from "react-icons/fa";

const Footer = () => {
  return (
    <Flex
      as="footer"
      height="50px"
      paddingLeft="10%"
      paddingRight="10%"
      alignItems="center"
      borderTop="1px solid"
      borderColor="gray.200"
      position="sticky"
      bottom={0}
      bg="white"
    >
      <ButtonGroup variant="link" spacing={4}>
        <Button fontSize="sm">
          <a
            href="https://earthfast.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            EarthFast.com
          </a>
        </Button>
        {/* <Button fontSize="sm">
          <a
            href="https://docs.earthfast.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Documentation
          </a>
        </Button> */}
        {/* <Button fontSize="sm">
          <a href="https://earthfast.com" target="_blank" rel="noopener noreferrer">
          Terms of Service
          </a>
        </Button>
        <Button fontSize="sm">
          <a href="https://earthfast.com" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </Button> */}
      </ButtonGroup>
      <Spacer />
      <ButtonGroup>
        <Button
          as="a"
          href="mailto:contact@earthfast.com"
          fontSize="sm"
          variant="link"
        >
          contact@earthfast.com
        </Button>
        <IconButton
          aria-label="GitHub"
          icon={<FaGithub />}
          variant="ghost"
          size="lg"
          as="a"
          href="https://github.com/earthfast"
          target="_blank"
          rel="noopener noreferrer"
        />
        {/* <IconButton
          aria-label="Telegram"
          icon={<FaTelegramPlane />}
          variant="ghost"
          size="md"
        />
        <IconButton
          aria-label="Twitter"
          icon={<FaTwitter />}
          variant="ghost"
          size="md"
          as="a"
          href="https://twitter.com/EarthFast"
          target="_blank"
          rel="noopener noreferrer"
        /> */}
      </ButtonGroup>
    </Flex>
  );
};

export default Footer;
