import { Box, Flex, Skeleton, Stack } from "@chakra-ui/react";

function LoadingProjects() {
  return (
    <Flex direction="column" align="center" mt={10}>
      <Flex justifyContent="space-between" alignItems="center" w="80%" mb={4}>
        <Box w={{ base: "12%" }}>
          <Skeleton height="40px" borderRadius="lg" />
        </Box>
        <Box w={{ base: "10%" }}>
          <Skeleton height="40px" borderRadius="lg" />
        </Box>
      </Flex>
      <Box w={{ base: "90%", md: "80%" }}>
        <Stack>
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
        </Stack>
      </Box>
    </Flex>
  );
}

export default LoadingProjects;
