import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
  Link as ChakraLink,
  Heading,
} from "@chakra-ui/react";
import { Link, Navigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, refetchUser } = useUser();

  const toast = useToast();

  if (user) {
    return <Navigate to="/" />;
  }

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/signup`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Signup failed due to server response",
        );
      }
      refetchUser();
      toast({
        title: "Signup Successful",
        description: "You have successfully signed up.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Signup error:", error);
      toast({
        title: "Signup Error",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width="100%"
      maxW="500px"
      mx="auto"
      my="auto"
      p="10"
      borderWidth="1px"
      borderRadius="lg"
      bg="white"
    >
      <Heading fontSize="2xl" textAlign="center" mb="6" as="h2">
        Sign Up
      </Heading>
      <form onSubmit={handleSignUp}>
        <VStack spacing="5">
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          <Button
            width="full"
            type="submit"
            isLoading={loading}
            variant="primary"
          >
            Sign Up
          </Button>
        </VStack>
      </form>
      <Text align="center" mt="4">
        Already have an account?{" "}
        <ChakraLink as={Link} to="/login" color="primary">
          Log in
        </ChakraLink>
      </Text>
    </Box>
  );
};

export default SignUp;
