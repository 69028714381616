import { Tooltip, Icon, Box } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

const FormHelpPopover = ({ text }: { text: string }) => {
  return (
    <Box display="inline-block">
      <Tooltip label={text} fontSize="sm">
        <Icon as={QuestionIcon} color="gray.500" cursor="pointer" />
      </Tooltip>
    </Box>
  );
};

export default FormHelpPopover;
