import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import LogIn from "../../views/LogIn";
import WaitlistRegistration from "../../views/WaitlistRegistration";
import SignUp from "../../views/SignUp";
import Projects from "../../views/Projects";
import ProjectUpdates from "../../views/ProjectUpdates";
import CreateProject from "../../views/CreateProject";
import Logs from "../../views/Logs";
import ProjectView from "../../views/ProjectView";
import ViewSite from "../../ViewSite";
import FileDeployment from "../../views/FileDeployment/";
import Callback from "../../Callback";
import { Box } from "@chakra-ui/react";

function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      // redirect to projects page by default
      element: <Navigate to="/projects" replace />,
    },
    {
      path: "/projects",
      element: <Projects />,
    },
    // {
    //   path: "/explorer",
    //   element: <Home />,
    // },
    {
      path: "/project_updates/:projectId",
      element: <ProjectUpdates />,
      loader: async ({ params }) => {
        return { projectId: params.projectId };
      },
    },
    {
      path: "/logs/:pipelineId",
      element: <Logs />,
    },
    {
      path: "/projects/create",
      element: <CreateProject />,
    },
    {
      path: "/projects/:projectId/deploy",
      element: <FileDeployment />,
    },
    {
      path: "/projects/:projectId",
      element: <ProjectView />,
    },
    {
      path: "/github/callback",
      element: <Callback />,
    },
    {
      path: "/login",
      element: <LogIn />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/waitlist-registration",
      element: <WaitlistRegistration />,
    },
    {
      path: "/view-site/:url",
      element: <ViewSite />,
      loader: async ({ params }) => {
        return { url: params.url };
      },
    },
    {
      path: "/:catchAll",
      element: <div>Not found</div>,
    },
  ]);

  return (
    <Box width="100%" display="flex">
      <RouterProvider router={router} />
    </Box>
  );
}

export default Router;
