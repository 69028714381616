import React from "react";
import { Text, Flex, Link as ChakraLink } from "@chakra-ui/react";
import { IoLogoGithub } from "react-icons/io";
import { FiUpload } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { FaCodeBranch } from "react-icons/fa";
import { FaCodeCommit } from "react-icons/fa6";

interface ProjectInfoDisplayProps {
  project: ProjectObject;
  fontSize?: string;
  color?: string;
  justifyContent?: "left" | "center";
}

const ProjectInfoDisplay: React.FC<ProjectInfoDisplayProps> = ({
  project,
  fontSize = "sm",
  color = "gray.700",
  justifyContent = "left",
}) => {
  if (project.projectType === "github") {
    const projectInfo = project.projectInfo as ProjectInfoGithub;
    return (
      <ChakraLink
        href={`https://github.com/${projectInfo?.repositoryFullName}`}
        fontSize={fontSize}
        display="inline-block"
        target="_blank"
        width="100%"
      >
        <Flex alignItems="center" gap={1} justifyContent={justifyContent}>
          <IoLogoGithub />
          <Text>{projectInfo?.repositoryFullName}</Text>
        </Flex>
        <Flex alignItems="center" gap={1} justifyContent={justifyContent}>
          <FaCodeBranch />
          <Text>{projectInfo?.branchName || "main"}</Text>
          {!!projectInfo?.lastCommitMessage && (
            <>
              <Text> / </Text>
              <FaCodeCommit />
              <Text>{projectInfo?.lastCommitMessage}</Text>
            </>
          )}
        </Flex>
      </ChakraLink>
    );
  } else if (project.projectType === "url") {
    const projectInfo = project.projectInfo as ProjectInfoUrl;
    return (
      <ChakraLink
        href={projectInfo?.url}
        display="inline-block"
        fontSize={fontSize}
        color={color}
        target="_blank"
        width="100%"
      >
        <Flex alignItems="center" gap={1} justifyContent={justifyContent}>
          <GoLinkExternal />
          <Text>{projectInfo?.url}</Text>
        </Flex>
        <Flex alignItems="center" gap={1} justifyContent={justifyContent}>
          <FaCodeBranch />
          <Text>{projectInfo?.branchName || "main"}</Text>
          {!!projectInfo?.lastCommitMessage && (
            <>
              <Text> / </Text>
              <FaCodeCommit />
              <Text>{projectInfo?.lastCommitMessage}</Text>
            </>
          )}
        </Flex>
      </ChakraLink>
    );
  } else if (project.projectType === "upload") {
    return (
      <Flex align="center" gap={1} justifyContent={justifyContent} width="100%">
        <FiUpload />
        <Text>Manual Upload</Text>
      </Flex>
    );
  }

  return null;
};

export default ProjectInfoDisplay;
