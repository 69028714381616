import {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";

const serverUrl = process.env.REACT_APP_SERVER_URL;

// Define the context value shape
export interface UserContextType {
  user: UserObject | null;
  isAuthenticated?: boolean;
  isLoadingUser?: boolean;
  refetchUser: () => void;
  logoutFn: () => void;
}

export const DEFAULT_CONTEXT_VALUE = {
  user: null,
  isAuthenticated: false,
  isLoadingUser: false,
  refetchUser: () => null,
  logoutFn: () => null,
};

// Create a context with default value
export const UserContext = createContext<UserContextType | undefined>(
  DEFAULT_CONTEXT_VALUE,
);

// Create a provider component
interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserObject | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const logoutFn = async () => {
    try {
      await fetch(`${serverUrl}/logout`, {
        method: "POST",
        credentials: "include",
      });
      setUser(null);
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const fetchUser = async () => {
    try {
      setIsLoadingUser(true);
      const response = await fetch(`${serverUrl}/user`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingUser(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        isLoadingUser,
        refetchUser: fetchUser,
        logoutFn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Create a custom hook to use the UserContext
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
